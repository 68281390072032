import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { TextSection } from "../../components/molecules/TextSection";
import { RoundedBoxShadowBox } from "../../components/atoms/RoundedBoxShadowBox";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { TextAndImageSection } from "../../components/molecules/TextAndImageSection";
import { LayoutBox, LayoutBoxMinimal } from "../../components/atoms/LayoutBox";

export const ForSurvivorsPageTemplate = ({
  title,
  description,
  topPanel,
  tools,
  features,
}) => {
  return (
    <>
      <div className="hero-survivor is-medium">
        <div className="hero-body pl-6 pr-6">
          <LayoutBoxMinimal>
            <div className="columns is-multiline">
              <div className="column is-9">
                <div className="section p-0 pb-3">
                  <h1
                    className="title is-size-3-mobile is-size-2-tablet is-size-1-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.2",
                    }}
                  >
                    {title}
                  </h1>
                </div>
              </div>
              <div className="column is-4">
                <div className="section p-0 pr-6">
                  <h2
                    className="subtitle has-text-weight-normal is-size-5-mobile is-size-5-tablet is-size-4-widescreen pl-0 pr-0"
                    style={{
                      color: "white",
                      lineHeight: "1.4",
                      paddingBottom: "6rem",
                    }}
                  >
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </LayoutBoxMinimal>
        </div>
      </div>
      <TextSection
        title={topPanel.title}
        mainText={topPanel.description}
        hasAltBackgroundColor={true}
      >
        {topPanel.hasButton && (
          <div className="columns is-centered pt-4">
            <div className="column is-3 pt-0">
              <Link
                to="/app-link?install"
                className="button is-primary is-rounded is-large is-fullwidth"
              >
                Get Started
              </Link>
            </div>
          </div>
        )}
      </TextSection>
      <LayoutBox>
        <div className="content has-text-centered">
          <h1
            className="title is-size-5-mobile is-size-4-tablet is-size-3-widescreen pl-0 pr-0"
            style={{
              lineHeight: "1",
            }}
          >
            {tools.title}
          </h1>
        </div>
        <div className="columns pt-6 pb-6 is-multiline is-centered">
          {tools.areas.map((a) => {
            const image = getImage(a.image) || a.image;

            return (
              <div className="column is-one-fifth" key={a.name}>
                <RoundedBoxShadowBox extraClasses="is-align-items-center has-text-centered pt-4 pb-4">
                  <figure className="image pb-4">
                    {typeof image === "object" && image.extension !== "svg" ? (
                      <GatsbyImage image={image} alt={a.name} />
                    ) : (
                      <img src={image.publicURL || image} alt={a.name} />
                    )}
                  </figure>

                  <p
                    className="has-text-centered is-flex-grow-1"
                    style={{
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    {a.name}
                  </p>
                </RoundedBoxShadowBox>
              </div>
            );
          })}
        </div>
      </LayoutBox>
      {features.map((feature, idx) => {
        return (
          <TextAndImageSection
            key={feature.title}
            title={feature.title}
            mainText={feature.description}
            image={feature.image}
            hasAltBackgroundColor={idx % 2 !== 1}
            isReverseOrder={idx % 2 !== 1}
            hasPortraitImage={true}
          >
            {feature.hasButton && (
              <div className="columns is-centered pt-4">
                <div className="column is-6 pt-0">
                  <Link
                    to="/app-link?install"
                    className="button is-primary is-rounded is-large is-fullwidth"
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            )}
          </TextAndImageSection>
        );
      })}
      <LayoutBox>
        <div className="columns is-centered">
          <div className="column is-3">
            <Link
              to="/app-link?install"
              className="button is-primary is-rounded is-large is-fullwidth"
            >
              Get Started
            </Link>
          </div>
        </div>
      </LayoutBox>
    </>
  );
};

ForSurvivorsPageTemplate.propTypes = {
  title: PropTypes.string,
};
