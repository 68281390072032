import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { ForSurvivorsPageTemplate } from "./ForSurvivorPageTemplate";
import Layout from "../components/Layout";

const ForSurvivorsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout
      title={frontmatter.meta_title}
      description={frontmatter.meta_description}
    >
      <ForSurvivorsPageTemplate
        title={frontmatter.title}
        description={frontmatter.heading}
        topPanel={frontmatter.topPanel}
        tools={frontmatter.tools}
        features={frontmatter.features}
      />
    </Layout>
  );
};

ForSurvivorsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ForSurvivorsPage;

export const forSurvivorsPageQuery = graphql`
  query ForSurvivorsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        meta_title
        meta_description
        topPanel {
          hasButton
          title
          description
        }
        tools {
          title
          areas {
            name
            image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: CONSTRAINED, width: 88)
              }
              extension
              publicURL
            }
          }
        }
        features {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
            }
            extension
            publicURL
          }
          hasButton
        }
      }
    }
  }
`;
